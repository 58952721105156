import fetch from '../utils/fetch'

export function getReserveFundList(query) {
  return fetch({
    url: '/money/reserve_fund/list',
    method: 'get',
    params: query
  })
}

export function createReserveFund(params) {
  return fetch({
    url: '/money/reserve_fund/add',
    method: 'post',
    params: params
  })
}

export function updateReserveFund(params) {
  return fetch({
    url: '/money/reserve_fund/update',
    method: 'post',
    params: params
  })
}

export function deleteReserveFund(id) {
  return fetch({
    url: '/money/reserve_fund/' + id,
    method: 'delete'
  })
}



export function getEarnestMoneyList(query) {
    return fetch({
      url: '/money/earnest_money/list',
      method: 'get',
      params: query
    })
  }

  export function createEarnestMoney(params) {
    return fetch({
      url: '/money/earnest_money/add',
      method: 'post',
      params: params
    })
  }

  export function updateEarnestMoney(params) {
    return fetch({
      url: '/money/earnest_money/update',
      method: 'post',
      params: params
    })
  }

  export function deleteEarnestMoney(id) {
    return fetch({
      url: '/money/earnest_money/' + id,
      method: 'delete'
    })
  }

export function merchantEarnestMoneyList(params) {
  return fetch({
    url: '/money/merchant_earnest_money/list',
    method: 'get',
    params
  })
}

export function merchantEarnestMoneyDetail(params) {
  return fetch({
    url: '/money/merchant_earnest_money/detail',
    method: 'get',
    params
  })
}
