import fetch from '../utils/fetch'

export function promotionCode(query) {
  return fetch({
    url: '/popularize/promoter/promotionCode',
    method: 'get',
    params: query
  })
}

export function promotionMerchantCode(query) {
  return fetch({
    url: '/popularize/promoter/promotionMerchantCode',
    method: 'get',
    params: query
  })
}

export function invitationConfig(query) {
  return fetch({
    url: '/popularize/invitationConfig',
    method: 'post',
    params: query
  })
}
