import fetch from '../utils/fetch'

export function saveServiceFee (query) {
  return fetch({
    url: '/service_fee/save',
    method: 'post',
    params: query,
    paramsType: 'json'
  })
}

export function goodsItems (query) {
  return fetch({
    url: '/goods/items',
    method: 'get',
    params: query
  })
}

export function delFeeItem (id) {
  return fetch({
    url: '/service_fee/items/' + id,
    method: 'delete'
  })
}

export function delMerchant (id) {
  return fetch({
    url: '/service_fee/merchant/' + id,
    method: 'delete'
  })
}

export function serviceFeeCheck (params) {
  return fetch({
    url: '/service_fee/check',
    method: 'post',
    params
  })
}
