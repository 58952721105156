<template>
  <el-dialog width="384px" :visible.sync="dialog" :close-on-click-modal="false" :before-close="handleClose" custom-class="play-custom-class">
      <span slot="title" class="play-title">
        扫描支付
      </span>
    <el-row>
      <div class="code-box">
        <span class="title">支付金额</span>
        <span class="sum">￥{{ codeObj.money }}</span>
        <QRCodeVue class="code-img" :value="codeObj.code_url" :size="212"></QRCodeVue>
        <!--        <el-image class="code-img" :src="codeImg"></el-image>-->
        <span class="hint">请用微信扫描二维码完成支付</span>
      </div>
    </el-row>
<!--    <span slot="footer" class="dialog-footer">
        <el-button @click="dialog = false">取 消</el-button>
    </span>-->
  </el-dialog>
</template>

<script>
import QRCodeVue from 'qrcode.vue';
export default {
  name: "ScanPayment",
  components: {
    QRCodeVue
  },
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    codeObj: {
      type: Object,
      default: () => {return {money: null,code_url:null}},
      required: true
    }
  },
  data() {
    return {
      wechatPayUrl: '',
      codeImg: require('@/assets/img/default_code.png')
    }
  },
  methods: {
    handleClose() {
      this.$emit('update-dialog', false)
    }
  }
}
</script>

<style scoped lang="scss">
.play-custom-class {
  .el-dialog__body {
    padding-bottom: 16px;
  }
  .play-title {
    font-size: 16px;
    font-weight: 600;
  }
  .code-box {
    width: 100%;
    height: 100%;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    .title {
      color: #333333;
      font-size: 14px;
      font-weight: 400;
    }
    .sum {
      font-weight: 500;
      font-size: 33px;
      color: #D9001B;
    }
    .code-img {
      margin-bottom: 20px;
      margin-top: 10px;
    }
    .hint {
      font-size: 12px;
      color: #555555;
    }
  }
}
</style>
