// 营销
const name = '商家'
import Layout from '@/view/layout' // 主框架

export default {
  path: '/merchant/marketing',
  component: Layout,
  children: [
    {
      path: 'popularize/agentSharePool',
      name: `推广员共享池`,
      component: () => import('@/view/merchant/popularize/agentSharePool'),
    },
    {
      path: 'popularize/agentManage',
      name: `推广员管理`,
      component: () => import('@/view/selfSellerPlug/agentManage'),
      children: [
        {
          path: 'detail',
          component: () => import('@/view/popularize/brokerageLog')
        },
        {
          path: 'child',
          component: () => import('@/view/popularize/children')
        }
      ]
    },
    {
      path: 'popularize/plugPerformance',
      name: `推广员业绩`,
      component: () => import('@/view/selfSellerPlug/plugPerformance'),
      children: [
        {
          path: 'detail',
          component: () => import('@/view/popularize/brokerageLog')
        },
        {
          path: 'child',
          component: () => import('@/view/popularize/children')
        },
        {
          path: 'detailOrder/:itemId?',
          component: () => import('@/view/mall/trade/order/detail'),
          meta: {
            footerFixed: true
          }
        }
      ]
    },
    {
      path: 'popularize/agentInviteCode',
      name: `推广员邀请码`,
      component: () => import('@/view/merchant/popularize/agentInviteCode'),
    },
  ]
}
